.fixed {
    position: fixed;
    width: calc(100% - 20px);
    top: 10px;
    z-index: 999;
}

.offset-top {
    padding-top: 200px;
}

.offset-left {
    padding-left: 0;
    @media only screen and (min-width: 600px) {
        padding-left: 20vw;
    }
}

.fullscreen {
  min-width: calc(100vw);
  min-height: calc(100vh);
  margin: -10px;
}