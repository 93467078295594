header {
    z-index: 100;
    @media only screen and (min-width: 620px) {
        margin: 0 auto;
    }

    &.main {
        // background: #fff;
    }
}

.header-intro {
    // background: $red-violet;
    // color: white;
    margin-bottom: 100px;
    margin-left: 0;
    font-size: 1.3em;
    font-weight: 800;
    // color: $red-violet;
    @media only screen and (min-width: 420px) {
        margin-bottom: 150px;
    }
}


.anagram {
    text-align: left;
    // display: flex;
    font-size: 1.3em;
    text-transform: lowercase;
    // letter-spacing: 3px;
    // justify-content: space-between;
    padding: 1em 0 .5em 0;
    margin: 0;
    color: black;
    background: none;
    font-weight: normal;
}

/* MENU */

.navigation {
    font-size: 1.3em;
}

.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-item {
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    font-weight: 800;
    letter-spacing: 3px;

    a {
        // color: inherit;
        text-decoration: none;
    }

    &:hover {
        &.is-active {
            border-bottom-color: black;
        }
    }
    &.is-active {
        border-bottom: 2px solid black;
    }
}