.project-text {
    font-size: 1.0em;
    figure {
        margin: 1.5em 0;
    }
    p {
        max-width: 400px;
        margin: 1.5em 0;
    }
    figure img {
        object-fit: contain;
        object-position: left;
        max-width: 800px;
        max-height: 800px;
        width: 100%;
    }

    &.offset-left {

    }
}


.project-header {
    position: relative;
    img {
        width: 100vw;
        height: 70vh;
        max-height: 800px;
        object-fit: cover;
        object-position: center bottom;
        margin: -10px -10px 0 -10px;
        @media only screen and (min-width: 420px) {
            height: 80vh;
        }
    }

    .project-header-content {
        @extend .offset-top;
    }

    &.has-header-image .project-header-content {

        padding-top: 50px;

    }
    
}

