@import "colors";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: Arial, Helvetica, sans-serif;
}

body {
    margin: 10px; // color: $red-violet;
    font-size: 18px;
}

a {
    color: black;
    text-decoration: none;
    &:hover {
        // border-bottom: 2px solid rgb(61,0,121);
        // text-decoration: underline;
        color: black;
        text-decoration: underline;
    }
}

h1,
h2,
h3 {
    // color: black;
}

h1 {
    font-size: 2em;
    line-height: 2em;
    font-weight: normal;
}

h2 {
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: bold;
}

h3 {
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

h4 {
    font-size: 1.0em;
    line-height: 1.0em;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.like-h4 {
    @extend h4;
}

.like-h3 {
    @extend h3;
}

.like-h2 {
    @extend h2;
}

.space {
    transition: background-color 1s;
    iframe {
        width: 100vw;
        min-height: 100vh;
        border: none;
        display: block;
    }
    &.loading {
        background-color: black;
    }
}

.batch {
    display: none;
    @media only screen and (min-width: 420px) {
        position: fixed;
        display: block;
        top: var(--y, 0);
        left: var(--x, 0);
        transform: translate3d(-50%, -50%, 0);
        z-index: 9999;
    }
}

figure figcaption {
    font-style: italic;
    max-width: 400px;
}

hr {
    border-color: black;
    max-width: 300px;
    border: none;
    border-bottom: 1px solid black;
}

p {
    margin: 1.5em 0;
}

main.main {
    // padding-top: 200px;
}

main {
    min-height: calc(100vh - 2.5em);
}

footer p {
    margin: 0;
}

@import "layout";
@import "header";
@import "home";
@import "pagination";
@import "project";