/* STARTSEITE */

// header.intro {
//     // background: $red-violet;
//     // color: white;
//     margin-bottom: 100px;
//     margin-left: 0;
//     font-size: 1em;
//     padding: 2em 4em;
//     border: 0.5em solid $red-violet;
//     @media only screen and (min-width: 420px) {
//         width: 500px;
//         margin-bottom: 150px;
//     }
// }


.project-list {
    margin: 0;
    padding: 0;
    list-style: none;
    perspective: 1000px;
    &.sinus {
        li {
            margin-bottom: 100px;
            @media only screen and (min-width: 420px) {
                // transform: translate3d( calc( var(--offset, 0) * (100vw - 400px - 20px)), 0, -1px) rotateY(calc(var(--offset, 0) * -5deg));
                transform: translate3d( calc( var(--offset, 0) * (100vw - 400px - 20px)), 0, -1px);
                margin-bottom: 150px;
            }
        }
    }
    &.straight {
        li {
            margin-bottom: 5em;
        }
    }
    &.narrow li {
        margin-bottom: 0.25em;
    }
}

.panel {
    display: block;
    width: fit-content;
    transform-style: preserve-3d;
    &:first-child {
        @media only screen and (min-width: 420px) {
            display: block;
        } // margin-top: -50px;
    }
    &.horizontal {
        @media only screen and (min-width: 720px) {
            display: flex;
            align-items: center;
            width: auto;
            .panel-caption {
                margin-left: 1em;
            }
            .panel-title {
                margin-top: 0;
            }
        }
    }
}

.panel-image {
    display: block;
    width: 100%;
    @media only screen and (min-width: 420px) {
        width: auto;
    }
}

.panel-link {
    // color: inherit;
    text-decoration: none;
}

.panel-title {
    margin: .5em 0 0 0;
}

.panel-caption {
    font-size: 1.0em;
}

.panel-content {
    @media only screen and (min-width: 420px) {
        max-width: 300px;
    }
}

section.front-articles {
    margin-bottom: 100px;

    @media only screen and (min-width: 420px) {
        margin-bottom: 150px;
    }
    .panel-content {
        max-width: 100%;
    }
}

section.all-projects {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (min-width: 1000px) {
        flex-direction: row;
    }
    .project-list:nth-child(1) {
        min-width: 200px;

        @media only screen and (min-width: 1000px) {
        }
    }
    .project-list:nth-child(2) {
        order: -1;
        margin-bottom: 50px;
        padding-right: 0px;


        @media only screen and (min-width: 1000px) {
            margin-bottom: 0px;
            order: initial;
            padding-right: 50px;
        }
    }
}

.article-list {
    .article {
        display: block;
        &:last-child .article-title {
            border-bottom: none;
        }
    }
    .article-title {
        // padding: .05em .5em .05em .3em;
        // margin: .35em 0; // text-transform: uppercase;
        // border-bottom: 2px solid $red-violet;
        display: inline-block;
    }

    .article:first-child {
        .article-title {
            margin-top: 0.5em;
        }
    }
    .article-link {
        &:hover .article-title {
            // background: black;
            text-decoration: underline;
        }
        .article-title {
            // background: $red-violet;
            // background: black;
            // color: #fff;
        } // color: inherit;
    }
    .article-image {
        display: block;
        width: 100%;
        @media only screen and (min-width: 820px) {
            width: auto;
        }
    }
}